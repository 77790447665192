<template>
    <Donation v-if="this?.$firebase?.account && this?.$wallet?.address" :wallet="$metamaskWallet"/>
    <SignIn :wallet="$metamaskWallet" v-else />
</template>
<script>
import Donation from "@/components/donation/donation.vue"
import SignIn from "@/components/donation/signIn.vue"

export default {
  components: { Donation, SignIn },
}
</script>